import {
  get, downFile
} from 'common/request'

export default {
  // 分页查询
  list: args => get('/appVersionLog/list', args),
  // 获取字典数据
  dictData: args => get('/dict-data/getByDicKind', args),
  // 导出
  exportXls: args => downFile('/appVersionLog/exportXls', args)
}
